import { useEffect, useState, useCallback } from 'react';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import { TrackedButton, TextField, Spinner } from '@automata/ui';
import { AllDriversPackage } from 'model/Archetypes';
import { DriversSelector } from 'components/DriversSelector';
import { useArchetypesVersions } from 'hooks/useArchetypesVersions';
import { initialGitWorkflow } from './defs/initialState';
import { WorkflowGit } from '@automata/api/apiSchemas';
import { EditorProps } from 'model/WorkflowTypes';
import { useSnackbar } from 'hooks/useSnackbar';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { schemas } from '@automata/api/zod';
import { InfoTooltip } from 'components/InfoTooltip';

const styles = {
  container: {
    paddingTop: '10px',
    paddingBottom: '20px',
    width: '100%',
  },
};

export const GitEditor = ({
  requestData,
  updateData,
  onContentUpdated,
}: EditorProps): JSX.Element => {
  const [content, setContent] = useState<WorkflowGit | null>(null);
  const [shouldUpdate, setShouldUpdate] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const initialValuesRaw = content ?? initialGitWorkflow;
  const { handleSubmit, register, reset, formState, control } =
    useForm<WorkflowGit>({
      defaultValues: {
        ssh_uri: initialValuesRaw.ssh_uri,
        ssh_private_key_pem: initialValuesRaw.ssh_private_key_pem,
        folder: initialValuesRaw.folder ?? ' ',
        workflow_file: initialValuesRaw.workflow_file ?? ' ',
        revision: {
          kind: 'commit',
          ref: initialValuesRaw.revision.ref,
        },
        resource_versions: {
          [AllDriversPackage]:
            initialValuesRaw.resource_versions[AllDriversPackage] ?? '',
        },
      },
      resolver: zodResolver(schemas.WorkflowGit),
    });

  useEffect(() => {
    reset({
      ssh_uri: initialValuesRaw.ssh_uri,
      ssh_private_key_pem: initialValuesRaw.ssh_private_key_pem,
      folder: initialValuesRaw.folder ?? '',
      workflow_file: initialValuesRaw.workflow_file ?? '',
      revision: {
        kind: 'commit',
        ref: initialValuesRaw.revision.ref,
      },
      resource_versions: {
        [AllDriversPackage]:
          initialValuesRaw.resource_versions[AllDriversPackage] ?? '',
      },
    });
  }, [
    initialValuesRaw.folder,
    initialValuesRaw.resource_versions,
    initialValuesRaw.revision.ref,
    initialValuesRaw.ssh_private_key_pem,
    initialValuesRaw.ssh_uri,
    initialValuesRaw.workflow_file,
    reset,
  ]);

  useEffect(() => {
    if (content === null && requestData !== undefined) {
      const newContent = requestData.definition.git ?? initialGitWorkflow;
      setContent(newContent);
    }
  }, [content, requestData]);

  const makeUpdate = useCallback(async () => {
    try {
      const workflow = await updateData({ git: content ?? undefined });
      workflow?.definition.git && setContent(workflow.definition.git);
      onContentUpdated(workflow);
      enqueueSnackbar('Workflow updated', {
        variant: 'success',
      });
    } catch (e) {
      enqueueSnackbar('Workflow failed to update', {
        variant: 'error',
      });
    }
  }, [content, enqueueSnackbar, onContentUpdated, updateData]);

  useEffect(() => {
    if (shouldUpdate) {
      makeUpdate();
      setShouldUpdate(false);
    }
  }, [makeUpdate, setShouldUpdate, shouldUpdate]);

  const submitValues = useCallback(
    (values: WorkflowGit) => {
      setShouldUpdate(false);
      setContent(values);
      setShouldUpdate(true);
    },
    [setContent]
  );

  const { all: archetypesVersions, loading } = useArchetypesVersions();

  if (loading) return <Spinner />;

  return (
    <form style={styles.container} onSubmit={handleSubmit(submitValues)}>
      <Stack spacing={2}>
        <TextField
          fullWidth
          label="SSH URI"
          placeholder="git@github.com:org/repo"
          {...register('ssh_uri')}
        />
        <TextField
          multiline
          fullWidth
          label={
            <Stack direction="row" gap={1}>
              <span>SSH Private Key (PEM)</span>
              <InfoTooltip copy="The key is redacted to protect your private information. This does not impact saving a valid private key." />
            </Stack>
          }
          placeholder="-----BEGIN OPENSSH PRIVATE KEY-----\n...\n-----END OPENSSH PRIVATE KEY-----"
          {...register('ssh_private_key_pem')}
        />
        <TextField
          fullWidth
          label="SHA1 of the commit to use"
          placeholder="2fd4e1c67a2d28fced849ee1bb76e7391b93eb12"
          {...register('revision.ref')}
        />
        <TextField
          fullWidth
          label="Folder to use inside the repository"
          placeholder="test-workflow/v2"
          {...register('folder')}
        />
        <TextField
          fullWidth
          label="Workflow file to use (defaults to __init__.py)"
          placeholder="sorter.py"
          {...register('workflow_file')}
        />
        <DriversSelector<WorkflowGit>
          name={`resource_versions.${AllDriversPackage}`}
          control={control}
          archetypesVersions={archetypesVersions}
        />
      </Stack>
      <Grid container spacing={2}>
        <Grid item mt={2} xs={12}>
          <TrackedButton
            trackLabel="wflow-git-save"
            fullWidth={false}
            loading={false}
            variant="contained"
            type="submit"
            disabled={!formState.isValid}
          >
            Save
          </TrackedButton>
        </Grid>
      </Grid>
    </form>
  );
};
