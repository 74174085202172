import { Modal } from '@automata/ui';
import { CommonProps } from './types';
import { Form } from './Form';
import { AuthenticationChecker } from './AuthenticationChecker';

export const ResourceActionsModal = (props: CommonProps) => {
  const { open, onClose, resourceAction, resourceType } = props;

  return (
    <AuthenticationChecker {...props}>
      <Modal
        open={open}
        onClose={onClose}
        title={`${resourceAction} ${resourceType}`}
      >
        <Form {...props} />
      </Modal>
    </AuthenticationChecker>
  );
};
