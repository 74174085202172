import { useCallback, useMemo, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/system/Stack';
import { useController, useForm } from 'react-hook-form';
import {
  Tag,
  WorkflowCreatePayload,
  WorkflowType,
} from '@automata/api/apiSchemas';
import { schemas } from '@automata/api/zod';
import {
  useCreateWorkflow,
  useAddTagToWorkflow,
  useGetWorkspaceTags,
} from '@automata/api/apiComponents';
import { useSnackbar } from 'hooks/useSnackbar';
import { AsyncStatus } from 'components/AsyncStatus';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Select,
  TagSelector,
  TextField,
  TrackedButton,
  Typography,
} from '@automata/ui';
import { behaviours, getWorkflowBehaviour } from 'model/WorkflowTypes';
import { capitaliseFirst } from 'utils/helpers';
import { useRouter } from 'next/router';
import { GlobalRoutes } from 'GlobalRoutes';
import { useRootUrl } from 'hooks/useRootUrl';
import { getErrorMessage } from '@automata/utils';
import { useWorkspaceID } from 'hooks/useWorkspaceID';

const defaultValues: WorkflowCreatePayload = {
  name: '',
  type: 'python',
};

interface Props {
  onClose: () => void;
}

export const CreateWorkflowForm = ({ onClose }: Props): JSX.Element => {
  const router = useRouter();
  const { workspaceID } = useRootUrl();
  const [selectedTags, setSelectedTags] = useState<Tag[]>([]);
  const { register, control, handleSubmit, formState } =
    useForm<WorkflowCreatePayload>({
      defaultValues: defaultValues,
      resolver: zodResolver(schemas.WorkflowCreatePayload),
    });
  const { field: typeField } = useController({
    name: 'type',
    control,
  });
  const {
    mutateAsync: createWorkflow,
    isLoading: isUpdating,
    error,
  } = useCreateWorkflow();

  const {
    mutate: addTagToWorkflowMutate,
    error: addTagToWorkflowError,
    isLoading: addTagToWorkflowIsLoading,
  } = useAddTagToWorkflow();

  const { enqueueSnackbar } = useSnackbar();

  const currentWorkspaceID = useWorkspaceID();

  const {
    data: allTags,
    isLoading: isLoadingAllTags,
    error: tagsError,
  } = useGetWorkspaceTags({
    pathParams: {
      workspaceID: workspaceID ?? '',
    },
  });

  const filteredTags = useMemo(() => {
    return allTags?.tags.filter((tag) => !tag.is_archived) ?? [];
  }, [allTags]);

  const onSubmit = useCallback(
    (formData: WorkflowCreatePayload) => {
      if (currentWorkspaceID === undefined || workspaceID === undefined) {
        return;
      }
      createWorkflow(
        {
          body: formData,
          pathParams: {
            workspaceID: currentWorkspaceID,
          },
        },
        {
          onSuccess: (data) => {
            if (selectedTags.length > 0) {
              selectedTags.forEach((tag) => {
                addTagToWorkflowMutate({
                  pathParams: {
                    workflowID: data.id,
                    tagID: tag.id,
                  },
                });
              });
            }
            enqueueSnackbar('Workflow created', { variant: 'success' });
            onClose();
            router.push(
              `${GlobalRoutes.workflow.replace(':org', workspaceID)}/${
                data.id
              }?editable=true`
            );
          },
          onError: (err) => {
            const error = getErrorMessage(err, 'Failed to create workflow');
            enqueueSnackbar(`Cannot create workflow: ${error}`, {
              variant: 'error',
            });
          },
        }
      );
    },
    [
      currentWorkspaceID,
      workspaceID,
      createWorkflow,
      enqueueSnackbar,
      onClose,
      router,
      selectedTags,
      addTagToWorkflowMutate,
    ]
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={2}>
        <Stack spacing={2}>
          <Stack direction="row" spacing={2}>
            <TextField
              label="Workflow Name"
              fullWidth
              {...register('name')}
              data-cy="wflow-form-name"
            />
            <Select
              labelId="select-label-workflow"
              label="Workflow type"
              data-testid="select-workflow-type"
              {...typeField}
              fullWidth
            >
              {Object.keys(behaviours)
                .filter(
                  (key: string) => behaviours[key as WorkflowType].enabled
                )
                .map((workflowType) => (
                  <MenuItem key={workflowType} value={workflowType}>
                    {`${capitaliseFirst(
                      getWorkflowBehaviour(workflowType as WorkflowType).label
                    )} Editor`}
                  </MenuItem>
                ))}
            </Select>
          </Stack>

          <TagSelector
            error={tagsError || addTagToWorkflowError}
            isLoading={isLoadingAllTags || addTagToWorkflowIsLoading}
            allTags={filteredTags}
            resourceTags={selectedTags}
            removeTag={(tag: Tag) => {
              setSelectedTags(selectedTags.filter((t) => t.id !== tag.id));
            }}
            addTag={(tag: Tag) => {
              setSelectedTags([...selectedTags, tag]);
            }}
            title={
              <Typography variant="subtitle2">
                Tags applied to this workflow
              </Typography>
            }
          />
        </Stack>
        {error !== null && <AsyncStatus error={error as any} />}
        <Stack direction="row" justifyContent="flex-end" spacing={2}>
          <TrackedButton
            trackLabel="form-cancel-create"
            disabled={isUpdating}
            variant="outlined"
            onClick={onClose}
            size="small"
          >
            Cancel
          </TrackedButton>
          <TrackedButton
            trackLabel="form-submit-create"
            loading={isUpdating}
            disabled={!formState.isValid}
            variant="contained"
            type="submit"
            size="small"
          >
            Create
          </TrackedButton>
        </Stack>
      </Stack>
    </form>
  );
};
