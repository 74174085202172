import { useEffect, useState } from 'react';
import { useTheme } from '@mui/material';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { TextField, TrackedButton, Spinner } from '@automata/ui';
import { AllDriversPackage } from 'model/Archetypes';
import { DriversSelector } from 'components/DriversSelector';
import { useArchetypesVersions } from 'hooks/useArchetypesVersions';
import { initialMaestroWorkflow } from './defs/initialState';
import {
  ReferenceDataDefinitions,
  RunParameterDefinitions,
  WorkflowMaestro,
} from '@automata/api/apiSchemas';
import {
  useGetMaestroVersions,
  useGetEvalVersions,
} from '@automata/api/apiComponents';
import { EditorProps } from 'model/WorkflowTypes';
import { useSnackbar } from 'hooks/useSnackbar';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { schemas } from '@automata/api/zod';
import { MaestroSelector } from 'components/MaestroVersionSelector';
import { EvalsSelector } from 'components/EvalsVersionSelector';
import { checkSemverVersionCompatibility } from './utils';
import zod from 'zod';
import { getErrorMessage } from '@automata/utils';
import { useURLSearchParams } from 'hooks/useURLSearchParams';
import { useFlag } from 'hooks/unleashHooks';

export const MaestroPackage = 'maestro';

export type MaestroEditorContent = {
  workflow: WorkflowMaestro;
  parameters: string;
  referenceData: string;
};

const workflowMaestroSchemaWithEvals = schemas.WorkflowMaestro.extend({
  resource_versions: zod.object({
    [MaestroPackage]: zod.string().min(1),
    [AllDriversPackage]: zod.string().min(1),
    evals_version: zod.string().min(1),
    evals_org: zod.string(),
  }),
});

const maestroEditorSchema = zod.object({
  workflow: schemas.WorkflowMaestro,
  parameters: zod.string(),
  referenceData: zod.string(),
});

const maestroEditorSchemaWithEvals = maestroEditorSchema.extend({
  workflow: workflowMaestroSchemaWithEvals,
});

export const MaestroEditor = ({
  requestData,
  updateData,
  onContentUpdated,
}: EditorProps): JSX.Element => {
  const [compatibleDriversVersionFilter, setCompatibleDriversVersionFilter] =
    useState<string>('v*');

  const { enqueueSnackbar } = useSnackbar();
  const [queryParams, setQueryParams] = useURLSearchParams();
  const isEditable = queryParams.editable === 'true';

  const { all: archetypesVersions } = useArchetypesVersions();
  const {
    data: maestroVersionsData,
    error: maestroVersionsError,
    isLoading: isMaestroVersionsLoading,
  } = useGetMaestroVersions({});

  const { data: evalVersionsData } = useGetEvalVersions({});

  const isRunParamsEnabled = useFlag('fe.run_params');
  const isEvalsEnabled = useFlag('fe.evals');

  if (maestroVersionsError) {
    enqueueSnackbar('Failed to fetch scheduler engine versions', {
      variant: 'error',
    });
  }

  const theme = useTheme();
  const { handleSubmit, reset, control, setValue, formState } =
    useForm<MaestroEditorContent>({
      defaultValues: {
        workflow: initialMaestroWorkflow,
        parameters: '[]',
        referenceData: '[]',
      },
      resolver: zodResolver(
        isEvalsEnabled ? maestroEditorSchemaWithEvals : maestroEditorSchema
      ),
    });

  useEffect(() => {
    if (requestData?.definition.maestro) {
      const { workflow, transport } = requestData.definition.maestro;

      const workflowConfig =
        typeof workflow === 'string'
          ? workflow
          : JSON.stringify(requestData.definition.maestro.workflow, null, 2);
      const transportConfig =
        typeof transport === 'string'
          ? transport
          : JSON.stringify(requestData.definition.maestro.transport, null, 2);

      reset({
        workflow: {
          ...requestData.definition.maestro,
          workflow: workflowConfig,
          transport: transportConfig,
        },
        parameters: JSON.stringify(
          requestData.attributes?.parameters ?? [],
          null,
          2
        ),
        referenceData: JSON.stringify(
          requestData.attributes?.reference_data_definitions ?? [],
          null,
          2
        ),
      });
    }
  }, [requestData, reset]);

  useEffect(() => {
    if (evalVersionsData) {
      // Check if evalVersionsData is available
      const { org_slug } = evalVersionsData;
      // Set the org_slug value explicitly using setValue
      setValue('workflow.resource_versions.evals_org', org_slug);
    }
  }, [evalVersionsData, setValue]);

  const makeUpdate = async (maestro: MaestroEditorContent) => {
    if (!maestro?.workflow) {
      enqueueSnackbar('Workflow data not found', { variant: 'error' });
      return;
    }

    let parameters: RunParameterDefinitions;
    try {
      parameters = JSON.parse(maestro.parameters);
    } catch (error) {
      enqueueSnackbar(getErrorMessage(error, 'failed to parse parameters'), {
        variant: 'error',
      });
      return;
    }

    let referenceData: ReferenceDataDefinitions;
    try {
      referenceData = JSON.parse(maestro.referenceData);
    } catch (error) {
      enqueueSnackbar(getErrorMessage(error, 'failed to parse parameters'), {
        variant: 'error',
      });
      return;
    }

    try {
      const workflow = await updateData(
        { maestro: maestro.workflow },
        { parameters: parameters, reference_data_definitions: referenceData }
      );
      onContentUpdated(workflow);
      enqueueSnackbar('Workflow updated', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(
        `Workflow failed to update: ${getErrorMessage(error, 'failed')}`,
        { variant: 'error' }
      );
    }
  };

  const checkDriversVersionCompatibility = (version: string) =>
    checkSemverVersionCompatibility(version, compatibleDriversVersionFilter);

  if (
    isMaestroVersionsLoading ||
    !archetypesVersions ||
    !maestroVersionsData ||
    !evalVersionsData
  ) {
    return <Spinner size={42} />;
  }

  return (
    <form
      name="scheduler-engine-editor-form"
      onSubmit={handleSubmit(makeUpdate)}
    >
      <Stack
        direction="row"
        gap={2}
        justifyContent="space-between"
        alignItems="flex-end"
        flexWrap="wrap"
      >
        <Stack direction="row" gap={2} flexWrap="wrap">
          <Stack direction="row" gap={2} my={2.5} pt={0.3}>
            <FormControlLabel
              control={
                <Switch
                  id="workflow-lock-switch"
                  checked={isEditable}
                  onChange={() => {
                    setQueryParams({ editable: !isEditable });
                  }}
                />
              }
              label="Edit mode"
              sx={{ mr: 0 }}
            />
            <Divider
              orientation="vertical"
              flexItem
              sx={{ color: theme.palette.divider, my: 0.5 }}
            />
            <Controller
              name="workflow.simulateDrivers"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      id="simulate-drivers-checkbox"
                      disabled={!isEditable}
                      checked={field.value}
                      onChange={(e) => field.onChange(e.target.checked)}
                    />
                  }
                  label="Simulate drivers"
                  sx={{ mr: 0 }}
                />
              )}
            />
            <Controller
              name="workflow.simulateTime"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      id="simulate-time-checkbox"
                      disabled={!isEditable}
                      checked={field.value}
                      onChange={(e) => field.onChange(e.target.checked)}
                    />
                  }
                  label="Simulate time"
                  sx={{ mr: 0 }}
                />
              )}
            />
            <Divider
              orientation="vertical"
              flexItem
              sx={{ color: theme.palette.divider, my: 0.5 }}
            />
          </Stack>
          <Stack direction="row" gap={2}>
            <Controller
              name={`workflow.resource_versions.${MaestroPackage}`}
              control={control}
              render={({ field }) => (
                <MaestroSelector
                  field={field}
                  maestroVersionsData={maestroVersionsData}
                  disabled={!isEditable}
                  setCompatibleDriversVersionFilter={
                    setCompatibleDriversVersionFilter
                  }
                />
              )}
            />
            {isEvalsEnabled && (
              <Controller
                name="workflow.resource_versions.evals_version"
                control={control}
                render={({ field }) => (
                  <EvalsSelector
                    field={field}
                    evalVersionsData={evalVersionsData}
                    disabled={!isEditable}
                  />
                )}
              />
            )}
            <DriversSelector<MaestroEditorContent>
              fullWidth={false}
              name={`workflow.resource_versions.${AllDriversPackage}`}
              control={control}
              archetypesVersions={archetypesVersions}
              disabled={!isEditable}
              compatibilityFilter={checkDriversVersionCompatibility}
            />
          </Stack>
        </Stack>
        <Stack mb={3}>
          <TrackedButton
            trackLabel="wflow-scheduler-engine-save"
            fullWidth={false}
            loading={false}
            variant="contained"
            type="submit"
            disabled={!isEditable || !formState.isValid}
          >
            Save
          </TrackedButton>
        </Stack>
      </Stack>
      <Grid container rowSpacing={1} columnSpacing={1} paddingBottom={2}>
        {(['workflow', 'transport'] as const).map((name) => (
          <Grid key={name} item xs={6}>
            <Controller
              name={`workflow.${name}`}
              control={control}
              render={({ field }) => (
                // TODO: Create a generic json editor text input component.
                <TextField
                  {...field}
                  id={`scheduler-engine-${name}-textfield`}
                  multiline
                  fullWidth
                  label={`${name} configuration`}
                  rows={40}
                  disabled={!isEditable}
                  InputProps={{
                    sx: {
                      '& textarea': {
                        fontFamily: 'monospace',
                        fontSize: '10px',
                        lineHeight: '1.5',
                      },
                    },
                  }}
                />
              )}
            />
          </Grid>
        ))}
        {isRunParamsEnabled && (
          <>
            <Grid item xs={6}>
              <Controller
                name={'parameters'}
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="scheduler-engine-parameters-textfield"
                    multiline
                    fullWidth
                    label="parameters configuration"
                    rows={20}
                    disabled={!isEditable}
                    InputProps={{
                      sx: {
                        '& textarea': {
                          fontFamily: 'monospace',
                          fontSize: '10px',
                          lineHeight: '1.5',
                        },
                      },
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name={'referenceData'}
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="scheduler-engine-referenceData-textfield"
                    multiline
                    fullWidth
                    label="reference data configuration"
                    rows={20}
                    disabled={!isEditable}
                    InputProps={{
                      sx: {
                        '& textarea': {
                          fontFamily: 'monospace',
                          fontSize: '10px',
                          lineHeight: '1.5',
                        },
                      },
                    }}
                  />
                )}
              />
            </Grid>
          </>
        )}
      </Grid>
    </form>
  );
};
