import { Select } from '@automata/ui';
import MenuItem from '@mui/material/MenuItem';
import { ControllerRenderProps } from 'react-hook-form';
import { logger } from '@automata/utils';
import { useSnackbar } from 'hooks/useSnackbar';
import { MaestroVersions } from '@automata/api/apiSchemas';
import { CustomVersionSelector } from './CustomVersionSelector';
import { checkAllowCustomVersions, getVersionCompatibleName } from './utils';
import { MaestroEditorContent } from 'components/MaestroEditor';
interface MaestroSelectorProps {
  field: ControllerRenderProps<
    MaestroEditorContent,
    `workflow.resource_versions.maestro`
  >;
  maestroVersionsData: MaestroVersions;
  setCompatibleDriversVersionFilter: (value: string) => void;
  disabled?: boolean;
}

export const MaestroSelector = ({
  field,
  maestroVersionsData,
  disabled = false,
  setCompatibleDriversVersionFilter,
}: MaestroSelectorProps): JSX.Element | null => {
  const { enqueueSnackbar } = useSnackbar();

  if (maestroVersionsData.versions.length === 0) {
    const err = 'No scheduler engine versions available';
    logger.error(err);
    enqueueSnackbar(err, { variant: 'error' });
  }

  if (checkAllowCustomVersions()) {
    return (
      <CustomVersionSelector
        reactHookFormField={field}
        disabled={disabled}
        versions={maestroVersionsData.versions}
        setCompatibleDriversVersionFilter={setCompatibleDriversVersionFilter}
      />
    );
  }

  return (
    <Select
      {...field}
      disabled={disabled}
      label="Scheduler Engine version"
      sx={{ width: '11rem' }}
      MenuProps={{ sx: { height: '50vh' } }}
      error={!field.value}
      helperText={field.value ? '' : 'Required'}
    >
      {maestroVersionsData.versions.map((version) => (
        <MenuItem
          key={version.name}
          value={version.name}
          onClick={() => {
            setCompatibleDriversVersionFilter(version.drivers_version);
          }}
        >
          {getVersionCompatibleName(version)}
        </MenuItem>
      ))}
    </Select>
  );
};
